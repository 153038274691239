import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as FollowersIcon } from '@icons/wolfkit-light/users-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { useProfileInfo } from '@entities/profile';
import ProfileTabLabel from './ProfileTabLabel';
const FollowersIconSized = () => (_jsx(Icon, { size: 16, IconComponent: FollowersIcon }));
const FollowersTabLabel = ({ profileNickname, isSelected = false, }) => {
    const { t } = useTranslation();
    const { profile } = useProfileInfo(profileNickname);
    return (_jsx(ProfileTabLabel, { IconComponent: FollowersIconSized, text: t('profile.tabs.followers'), amount: (profile === null || profile === void 0 ? void 0 : profile.followersCount.toString()) || '0', selected: isSelected }));
};
export default FollowersTabLabel;
