import { matchPathPartial } from '@app/routing/helpers';
import { RouteNames } from '@app/routing/types';
export const getProfilePage = (pathname) => {
    let profilePage;
    if (matchPathPartial(RouteNames.MY_PROFILE, pathname)) {
        profilePage = 'my_profile';
    }
    else if (matchPathPartial(RouteNames.PROFILE, pathname)) {
        profilePage = 'profile';
    }
    return profilePage;
};
export const getProfileTabRouteName = (tab, profilePage) => {
    let routeName;
    switch (tab) {
        case 'portfolios':
            if (profilePage === 'my_profile') {
                routeName = RouteNames.MY_PROFILE_PORTFOLIOS;
            }
            else if (profilePage === 'profile') {
                routeName = RouteNames.PROFILE_PORTFOLIOS;
            }
            break;
        case 'followers':
            if (profilePage === 'my_profile') {
                routeName = RouteNames.MY_PROFILE_FOLLOWERS;
            }
            else if (profilePage === 'profile') {
                routeName = RouteNames.PROFILE_FOLLOWERS;
            }
            break;
        case 'followings':
            if (profilePage === 'my_profile') {
                routeName = RouteNames.MY_PROFILE_FOLLOWINGS;
            }
            else if (profilePage === 'profile') {
                routeName = RouteNames.PROFILE_FOLLOWINGS;
            }
            break;
        case 'activity':
            if (profilePage === 'my_profile') {
                routeName = RouteNames.MY_PROFILE_ACTIVITY;
            }
            else if (profilePage === 'profile') {
                routeName = RouteNames.PROFILE_ACTIVITY;
            }
            break;
        case 'copiers':
            if (profilePage === 'my_profile') {
                routeName = RouteNames.MY_PROFILE_COPIERS;
            }
            else if (profilePage === 'profile') {
                routeName = RouteNames.PROFILE_COPIERS;
            }
            break;
        default:
            break;
    }
    return routeName;
};
// TODO: should move to PortfolioTabsWidget
// export const getPortfolioTabRouteName = (
//     tab: PortfolioTab,
//     profilePage: ProfilePage,
// ): RouteNames | undefined => {
//     let routeName: RouteNames | undefined
//
//     switch (tab) {
//         case 'information':
//             if (profilePage === 'my_profile') {
//                 routeName = RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION
//             } else if (profilePage === 'profile') {
//                 routeName = RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION
//             }
//             break
//         case 'current_positions':
//             if (profilePage === 'my_profile') {
//                 routeName = RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS
//             } else if (profilePage === 'profile') {
//                 routeName = RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS
//             }
//             break
//         case 'history':
//             if (profilePage === 'my_profile') {
//                 routeName = RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY
//             } else if (profilePage === 'profile') {
//                 routeName = RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY
//             }
//             break
//         case 'copiers':
//             if (profilePage === 'my_profile') {
//                 routeName = RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS
//             } else if (profilePage === 'profile') {
//                 routeName = RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS
//             }
//             break
//         default:
//             break
//     }
//
//     return routeName
// }
const matchPathToProfileTab = (pathname) => {
    let tab;
    if (matchPathPartial(RouteNames.MY_PROFILE_PORTFOLIOS, pathname) ||
        matchPathPartial(RouteNames.PROFILE_PORTFOLIOS, pathname)) {
        tab = 'portfolios';
    }
    else if (matchPathPartial(RouteNames.MY_PROFILE_FOLLOWERS, pathname) ||
        matchPathPartial(RouteNames.PROFILE_FOLLOWERS, pathname)) {
        tab = 'followers';
    }
    else if (matchPathPartial(RouteNames.MY_PROFILE_FOLLOWINGS, pathname) ||
        matchPathPartial(RouteNames.PROFILE_FOLLOWINGS, pathname)) {
        tab = 'followings';
    }
    else if (matchPathPartial(RouteNames.MY_PROFILE_ACTIVITY, pathname) ||
        matchPathPartial(RouteNames.PROFILE_ACTIVITY, pathname)) {
        tab = 'activity';
    }
    else if (matchPathPartial(RouteNames.MY_PROFILE_COPIERS, pathname) ||
        matchPathPartial(RouteNames.PROFILE_COPIERS, pathname)) {
        tab = 'copiers';
    }
    return tab;
};
export { 
// eslint-disable-next-line import/prefer-default-export
matchPathToProfileTab, };
