import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as PortfolioIcon } from '@icons/custom/portfolio-light.svg';
import Icon from '@shared/ui/icons/Icon';
import ProfileTabLabel from './ProfileTabLabel';
const PortfolioIconSized = () => (_jsx(Icon, { size: 16, IconComponent: PortfolioIcon }));
const PortfoliosTabLabel = ({ isSelected = false, isClientProfile = undefined, }) => {
    const { t } = useTranslation();
    return (_jsx(ProfileTabLabel, { IconComponent: PortfolioIconSized, text: t(isClientProfile ? 'profile.tabs.active_portfolios' : 'profile.tabs.portfolios', { ns: 'translation' }), amount: '0', selected: isSelected }));
};
export default PortfoliosTabLabel;
