import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as FollowingsIcon } from '@icons/custom/user-friend-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { useProfileInfo } from '@entities/profile';
import ProfileTabLabel from './ProfileTabLabel';
const FollowingsIconSized = () => (_jsx(Icon, { size: 16, IconComponent: FollowingsIcon }));
const FollowingsTabLabel = ({ profileNickname, isSelected = false, }) => {
    const { t } = useTranslation();
    const { profile } = useProfileInfo(profileNickname);
    return (_jsx(ProfileTabLabel, { IconComponent: FollowingsIconSized, text: t('profile.tabs.following'), amount: (profile === null || profile === void 0 ? void 0 : profile.followingCount.toString()) || '0', selected: isSelected }));
};
export default FollowingsTabLabel;
