import { jsx as _jsx } from "react/jsx-runtime";
import PortfoliosTabLabel from '@widgets/ProfileTabsWidget/ui/tabs/PortfoliosTabLabel';
import FollowersTabLabel from '@widgets/ProfileTabsWidget/ui/tabs/FollowersTabLabel';
import FollowingsTabLabel from '@widgets/ProfileTabsWidget/ui/tabs/FollowingsTabLabel';
import ActivityTabLabel from '@widgets/ProfileTabsWidget/ui/tabs/ActivityTabLabel';
import CopiersTabLabel from '@widgets/ProfileTabsWidget/ui/tabs/CopiersTabLabel';
const getClientTabs = (profileNickname, selectedTab) => ([
    {
        value: 'portfolios',
        label: (_jsx(PortfoliosTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'portfolios', isClientProfile: true })),
    },
    {
        value: 'followers',
        label: (_jsx(FollowersTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'followers' })),
    },
    {
        value: 'followings',
        label: (_jsx(FollowingsTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'followings' })),
    },
    {
        value: 'activity',
        label: (_jsx(ActivityTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'activity' })),
    },
]);
const getTraderTabs = (profileNickname, selectedTab) => ([
    {
        value: 'portfolios',
        label: (_jsx(PortfoliosTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'portfolios' })),
    },
    {
        value: 'followers',
        label: (_jsx(FollowersTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'followers' })),
    },
    {
        value: 'followings',
        label: (_jsx(FollowingsTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'followings' })),
    },
    {
        value: 'copiers',
        label: (_jsx(CopiersTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'copiers' })),
    },
    {
        value: 'activity',
        label: (_jsx(ActivityTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'activity' })),
    },
]);
export { getClientTabs, getTraderTabs, };
