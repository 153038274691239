import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '@shared/ui/navigation/Tabs';
import { ContainerColumn } from '@components/styled';
import { useProfileInfo } from '@entities/profile';
import useProfileTabsNavigation from '@widgets/ProfileTabsWidget/api/useProfileTabsNavigation';
import { matchPathToProfileTab } from '@widgets/ProfileTabsWidget/lib';
import { getClientTabs, getTraderTabs } from './utils';
const Container = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
}));
const TabsStyled = styled((Tabs))((props) => ({
    minHeight: 'unset',
    '& .MuiTabs-flexContainer': {
        borderBottom: 'none',
        gap: props.theme.spacing_sizes.xl,
        padding: 0,
    },
    '& .MuiTab-root': {
        margin: 0,
        padding: `${props.theme.spacing_sizes.s}px ${props.theme.spacing_sizes.xs * 0.5}px
                  ${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.xs * 0.5}px`,
        minHeight: 'unset',
        minWidth: 'unset',
    },
    '& .MuiTab-root h6': {
        fontSize: 12,
        lineHeight: '18px',
    },
}));
const ProfileTabsWidget = ({ className = undefined, profileNickname, }) => {
    const location = useLocation();
    const { navigateToProfileTab } = useProfileTabsNavigation();
    const currentTab = useMemo(() => matchPathToProfileTab(location.pathname), [location]);
    const { profile } = useProfileInfo(profileNickname);
    const tabs = useMemo(() => {
        if (!profile || !currentTab) {
            return [];
        }
        if (profile.type === 'CLIENT') {
            return getClientTabs(profileNickname, currentTab);
        }
        return getTraderTabs(profileNickname, currentTab);
    }, [profileNickname, profile, currentTab]);
    const onTabChange = (selectedTab) => {
        if (profile) {
            navigateToProfileTab(selectedTab, { params: { nickname: profile.username } });
        }
    };
    useEffect(() => {
        if (profile && !currentTab) {
            navigateToProfileTab('portfolios', {
                params: { nickname: profile.username },
                replace: true,
            });
        }
    }, [
        profile,
        currentTab,
        navigateToProfileTab,
    ]);
    return (_jsx(Container, { className: className, children: _jsx(TabsStyled, { tabs: tabs, activeTab: currentTab, onChange: onTabChange }) }));
};
export default ProfileTabsWidget;
